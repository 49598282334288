@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'flowbite/dist/flowbite.css';

@font-face {
  font-family: 'Poppins-medium';
  src: url('fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@layer base {
  :root {
    --destructive-foreground: hsl(0, 0%, 100%);
    --secondary-foreground: hsl(120, 20%, 95%);
    --popover-foreground: hsl(120, 25%, 10%);
    --primary-foreground: hsl(120, 20%, 95%);
    --accent-foreground: hsl(120, 20%, 95%);
    --muted-foreground: hsl(120, 25%, 10%);
    --card-foreground: hsl(120, 25%, 10%);
    --destructive: hsl(0, 50%, 50%);
    --background: hsl(120, 20%, 95%);
    --foreground: hsl(120, 25%, 10%);
    --: hsl(120, 25%, 10%);
    --popover: hsl(120, 20%, 95%);
    --primary: hsl(120, 25%, 10%);
    --border: hsl(120, 25%, 10%);
    --accent: hsl(120, 25%, 10%);
    --muted: hsl(120, 20%, 85%);
    --input: hsl(120, 20%, 60%);
    --card: hsl(0, 0%, 100%);
    --ring: hsl(120, 25%, 10%);
    --radius: 0.25rem;
  }
}

@layer base {
  * {
    border-color: var(--border);
  }

  body {
    color: var(--foreground);
    font-family: var(--font-body);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-heading);
  }
}

@layer components {
  .bg-primary {
    background-color: var(--primary);
  }

  .text-primary-foreground {
    color: var(--primary-foreground);
  }

  .bg-secondary {
    background-color: var(--secondary);
  }

  .text-secondary-foreground {
    color: var(--secondary-foreground);
  }

  .bg-accent {
    background-color: var(--accent);
  }

  .text-accent-foreground {
    color: var(--accent-foreground);
  }

  .bg-muted {
    background-color: var(--muted);
  }

  .text-muted-foreground {
    color: var(--muted-foreground);
  }

  .bg-card {
    background-color: var(--card);
  }

  .text-card-foreground {
    color: var(--card-foreground);
  }

  .border-input {
    border-color: var(--input);
  }

  .ring-ring {
    ring-color: var(--ring);
  }

  .ring-offset-background {
    ring-offset-color: var(--background);
  }

  .rounded-md {
    border-radius: var(--radius);
  }
}

.pro.hidden.md\:block {
  width: 10%;
}

.carousel-outer-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.carousel-inner-container {
  flex-grow: 1;
  overflow: hidden;
  margin: 0 20px; /* Adds space between arrows and content */
}

.cards-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.cards-container::-webkit-scrollbar {
  display: none; /* WebKit */
}

.arrow {
  color: var(--primary);
  padding: 10px;
  border: none;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.card-link {
  flex: 0 0 auto;
  width: 300px; /* Adjust based on your design */
  margin-right: 20px; /* Space between cards */
}

.card-link:last-child {
  margin-right: 0;
}

/* Add any additional styling for your cards here */

.bg-cutler-green {
  background-color: #00694e;
}

.text-cutler-green {
  color: #00694e;
}

.hover\:bg-cutler-green-hover:hover {
  background-color: #005941;
}

.stroke-cutler-green {
  stroke: #00694e;
}

.low-z {
  z-index: 0 !important;
}

.quill {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

.ql-toolbar {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.ql-container {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.loading-container {
  position: fixed;
  inset: 0;
  background-color: rgba(249, 250, 251, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  z-index: 50;
  backdrop-filter: blur(4px);
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.spinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #6366f1;
  animation: spinner-grow 1s ease infinite;
}

.loading-text {
  color: #4b5563;
  font-family: system-ui, -apple-system, sans-serif;
  font-size: 0.875rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes rotate {
  100% { transform: rotate(360deg) }
}

@keyframes spinner-grow {
  0% {
    transform: rotate(0deg);
    border-width: 3px;
  }
  50% {
    transform: rotate(180deg);
    border-width: 1px;
  }
  100% {
    transform: rotate(360deg);
    border-width: 3px;
  }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: .5; }
}

@keyframes rot55 {
  to {
    transform: rotate(360deg);
  }
}

.card {
  margin: 10px;
  padding: 20px;
  width: 300px;
  height: 180px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: '';
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.text {
  color: white;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
  margin-top: -10px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 400;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

.card-5 {
  background: #81c784;
}

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

.cards-container {
  display: flex;
  justify-content: space-between;
}

.card-link {
  text-decoration: none;
}

table th:first-child {
  border-radius: 12px 0 0 12px;
}

table th:last-child {
  border-radius: 0 12px 12px 0;
}

/* Import Poppins font - add this at the top of your CSS file */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* Base styles that apply to all inputs */
input {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

/* Style 1 - Minimal Clean */
.input-minimal {
  padding: 10px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s ease;
}

.input-minimal:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

/* Style 2 - Modern Bottom Border */
.input-modern {
  padding: 12px 0;
  border: none;
  border-bottom: 2px solid #e2e8f0;
  background-color: transparent;
  transition: all 0.3s ease;
}

.input-modern:focus {
  outline: none;
  border-bottom-color: #6366f1;
}

/* Style 3 - Floating Label */
.input-floating-container {
  position: relative;
  margin-top: 20px;
}

.input-floating {
  padding: 12px 16px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  background: transparent;
  transition: all 0.3s ease;
}

.input-floating-label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #94a3b8;
  pointer-events: none;
  transition: all 0.3s ease;
  background-color: white;
  padding: 0 4px;
}

.input-floating:focus,
.input-floating:not(:placeholder-shown) {
  border-color: #8b5cf6;
  outline: none;
}

.input-floating:focus + .input-floating-label,
.input-floating:not(:placeholder-shown) + .input-floating-label {
  top: 0;
  font-size: 12px;
  transform: translateY(-50%);
}

/* Style 4 - Solid Background */
.input-solid {
  padding: 12px 16px 12px 40px;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  background-color: #f8fafc;
  transition: all 0.3s ease;
}

.input-solid:focus {
  outline: none;
  border-color: #10b981;
  background-color: white;
  box-shadow: 0 0 0 4px rgba(16, 185, 129, 0.1);
}

/* Style 5 - Neumorphic */
.input-neumorphic {
  padding: 14px 20px;
  color: #1e293b;
  border: none;
  border-radius: 12px;
  background: #f1f5f9;
  box-shadow: 
    inset 2px 2px 5px rgba(0, 0, 0, 0.1),
    inset -2px -2px 5px rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
}

.input-neumorphic:focus {
  outline: none;
  box-shadow: 
    inset 3px 3px 7px rgba(0, 0, 0, 0.1),
    inset -3px -3px 7px rgba(255, 255, 255, 0.8);
}

/* Optional: Disabled state for all inputs */
input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Optional: Placeholder styling for all inputs */
input::placeholder {
  color: #94a3b8;
  opacity: 1;
}