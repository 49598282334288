.gradient {
  border-radius: 45px;
  background: linear-gradient(47deg, #4a25e1 26.3%, #7b5aff 86.4%) !important;
  color: white;
  transform: scale(1);
  transition: transform 1s ease;
}

.gradient:hover {
  transform: scale(1.03);
  box-shadow: 18px 30px 50px 2px rgba(98, 156, 215, 0.18);
}
