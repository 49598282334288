.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee-content {
  display: flex;
  animation: marquee 25s linear infinite; /* Controls the speed and repetition */
}

.box-shadow {
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
}

.marquee-image {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 800px) {
  .marquee-image {
    height: 4rem;
  }
}

/* Animation to move the marquee from right to left */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.backdropblur {
  backdrop-filter: blur(16px);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

.backdropblur-inside {
  backdrop-filter: blur(16px);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5)
  );
}
/* Zoom in animation */
.scale-0 {
  transform: scale(0);
}
.scale-100 {
  transform: scale(1);
}

/* Transition for smooth opening */
.transition-transform {
  transition: transform 0.3s ease-in-out;
}

/* Shadow for floating effect */
.shadow-lg {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.custom-gradient {
  background-color: (15.46deg, rgb(74, 37, 225) 26.3%, rgb(123, 90, 255) 86.4%);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
